import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as businessActions from "../../actions/businessActions";
import {
  BusinessBenefits,
  BusinessDescription,
  BusinessMap,
  BusinessMenu,
  BusinessSocial,
} from "../../components/EntityPages/BusinessAndRestaurant";
import { EntityHeader, EntityWrongInfo, EntityReviews } from "../../components/EntityPages/";
import { useParams, useNavigate } from "react-router-dom";
import classes from "./Business.module.css";
import GreyIconTags from "../../components/Entities/IconTags/GreyIconTags/GreyIconTags";
import { BRANCHES } from "../../resources/config";
import { businessUtils, reviewUtils } from "../../utils/entityUtils";
import { logEvents } from "../../eventsManager";
import config from "../../config";

const Business = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { branchId } = useParams();
  const businessBranch = useSelector((state) => state.business.fullBusiness);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const entityTags = useSelector((state) => state.entities.entityTags);
  const reviewHashTags = useSelector((state) => state.entities.reviewHashTags);
  const pendingReviews = useSelector((state) => state.user.userPendingReviews);

  const [preparedBranch, setPreparedBranch] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [businessEventProps, setBusinessEventProps] = useState({});

  useEffect(() => {
    businessActions.getFullBusiness(dispatch, branchId).catch(() => {
      navigate("/not-found"); 
    });

    window.scrollTo(0, 0);
  }, []);

  const getBusinessEventsProps = (preparedBranch) => {
    return {
      business_id: preparedBranch.id,
      business_type: BRANCHES,
      business_name: preparedBranch.name,
      business_address: preparedBranch.address,
      chain_id: preparedBranch.businessId,
      branch_name: preparedBranch.location ? preparedBranch.location.name : "",
    };
  };

  useEffect(() => {
    if (
      businessBranch &&
      entityCategories &&
      entityTags &&
      reviewHashTags &&
      reviewHashTags.businessTags &&
      pendingReviews
    ) {
      if (businessBranch.id && businessBranch.id == branchId) {
        const prepared = businessUtils.prepareBusinessBranch(
          businessBranch,
          entityCategories.businessesSubs,
          entityTags
        );
        prepared.reviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.businessTags)
        );

        prepared.userPendingReviews = pendingReviews.filter(
          (review) => review.entity_type == "business-branch" && review.entity_id == branchId
        );

        prepared.userPendingReviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.businessTags)
        );

        setPreparedBranch(prepared);
        const props = getBusinessEventsProps(prepared);

        logEvents("screen_business_open", props);
        setBusinessEventProps(props);
        setIsLoading(false);
      }
    }
  }, [businessBranch, entityCategories, reviewHashTags, pendingReviews, entityTags]);

  const componentToPrint = useRef();
  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const isUS = config.currentCountry === "US";

  return (
    <div className={classes.mainContainer} ref={componentToPrint}>
      <div>
        <EntityHeader
          entityType={BRANCHES}
          className={classes.headerContainer}
          title={preparedBranch.name}
          thumbnail={preparedBranch.logo ? preparedBranch.logo : ""}
          rating={preparedBranch.rating}
          numOfReviews={preparedBranch.num_of_reviews}
          categories={preparedBranch.business_subcats}
          coverImages={preparedBranch.pics ? preparedBranch.pics.map((pic) => pic.url) : []}
          isLoading={isLoading}
          componentToPrint={reactToPrintContent}
        />
        <div className={classes.gridContainer}>
          <GreyIconTags
            entity={preparedBranch}
            className={classes.businessIcons}
            showNames={true}
            isLoading={isLoading}
          />
          {
            <BusinessDescription
              className={classes.descriptionContainer}
              description={preparedBranch.description}
              distance={preparedBranch.distance}
              location={preparedBranch.address}
              daily_opening={preparedBranch.schedule ? preparedBranch.schedule.daily_opening : ""}
              phone={preparedBranch.phone_number}
              isLoading={isLoading}
              eventProps={businessEventProps}
            />
          }
          <BusinessMap
            className={classes.mapContainer}
            entity={preparedBranch}
            isLoading={isLoading}
            lastUpdate={preparedBranch.last_update}
          />
          {!isUS && (
            <BusinessBenefits
              entityType={BRANCHES}
              entity={preparedBranch}
              businessIcon={preparedBranch.logo ? preparedBranch.logo : ""}
              className={classes.benefitsContainer}
              benefits={preparedBranch.discount_list}
              isLoading={isLoading}
              entityName={preparedBranch.name}
            />
          )}
          {!isLoading && !isUS && (
            <BusinessMenu
              className={classes.menuContainer}
              menu={preparedBranch.menu ? preparedBranch.menu : preparedBranch.menu}
              entityName={preparedBranch.name}
            />
          )}
          {!isLoading && (
            <BusinessSocial
              className={classes.socialsContainer}
              restaurantName={preparedBranch.name}
              websiteLink={preparedBranch.website_url}
              instagramLink={preparedBranch.instagram_url}
              facebookLink={preparedBranch.facebook_url}
              linkedinLink={preparedBranch.linkedin_url}
            />
          )}
          {!isLoading && (
            <EntityWrongInfo
              className={classes.wrongInfoContainer}
              entityName={preparedBranch.name}
            />
          )}
        </div>
        {!isLoading && (
          <EntityReviews
            className={classes.reviewsContainer}
            numOfReviews={preparedBranch.num_of_reviews}
            rating={preparedBranch.rating}
            reviews={preparedBranch.reviews}
            pendingReviews={preparedBranch.userPendingReviews}
            entityType={BRANCHES}
            entityId={preparedBranch.id}
            entityName={preparedBranch.name}
            onRefreshReviews={() => businessActions.getFullBusiness(dispatch, branchId)}
            eventProps={businessEventProps}
          />
        )}
      </div>
    </div>
  );
};

export default Business;
