import { t } from "i18next";
import moment from "moment";
import propTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import config from "../../../../config";
import { logEvents } from "../../../../eventsManager";
import icons, { iconsAlts } from "../../../../resources/icons";
import { getCurrentCountry } from "../../../../utils/utilFunctions";
import EntityBenefitMark from "../../../Entities/EntityBenefitMark/EntityBenefitMark";
import classes from "./BusinessDescription.module.css";
import { BRANDS } from "../../../../resources/config";
import ReactMarkdown from "react-markdown";

const BusinessDescription = ({
  description,
  reservationName,
  reservation,
  distance,
  location,
  google_place_id,
  daily_opening,
  phone,
  entityType,
  className,
  isLoading,
  eventProps,
  hideLocation = false,
  hideSchedule = false,
  showTopDivider = true,
}) => {
  const getOpeningDays = (from, to) => {
    const weekDays = [
      t("days_sun"),
      t("days_mon"),
      t("days_tue"),
      t("days_wed"),
      t("days_thur"),
      t("days_fri"),
      t("days_sat"),
    ];

    return weekDays[from];
  };

  const getOpeningShifts = (shifts) => {
    const country = getCurrentCountry();
    return shifts
      .map((shift) => {
        const from = shift.from ? shift.from.replace(":00.000", "") : "";
        const to = shift.to ? shift.to.replace(":00.000", "") : "";
        if (country === "IL") {
          return `${from} - ${to}`;
        } else {
          return `${moment(from, "HH:mm").format("h:mm a")} - ${moment(to, "HH:mm").format(
            "h:mm a"
          )}`;
        }
      })
      .join("\n");
  };

  const getDisplayHours = (openings, country) => {
    let dailyOpenings = [];
    // [[1,0,"12:00","00:00"]] NEW
    // { from_day: 0, to_day: 1, from: 19, to: 21},{ from_day: 1, to_day: 1, from: 19, to: 21} OLD
    openings.forEach((opening) => {
      if (opening.all_day) {
        dailyOpenings.push({ from_day: opening.from_day, to_day: opening.to_day, all_day: true });
      } else {
        //const existingOpening = dailyOpenings.find((dailyOpening) => opening[0] == dailyOpening.from_day && opening[1] == dailyOpening.to_day);
        if (openings.length === 1 && opening[0] === 1 && opening[1] === 0) {
          for (let i = 0; i < 7; i++) {
            dailyOpenings.push({ from_day: i, shifts: [{ from: opening[2], to: opening[3] }] });
          }
        } else {
          if (opening[0] < opening[1]) {
            if (opening[1] - opening[0] > 1) {
              for (let i = opening[0]; i <= opening[1]; i++) {
                let existOpening = dailyOpenings.find((o) => o.from_day === i);
                if (existOpening) {
                  let existOpeningIndex = dailyOpenings.findIndex((o) => o === existOpening);
                  dailyOpenings[existOpeningIndex].shifts.push({
                    from: opening[2],
                    to: opening[3],
                  });
                } else {
                  dailyOpenings.push({
                    from_day: i,
                    shifts: [{ from: opening[2], to: opening[3] }],
                  });
                }
              }
            } else {
              for (let i = opening[0]; i < opening[1]; i++) {
                let existOpening = dailyOpenings.find((o) => o.from_day === i);
                if (existOpening) {
                  let existOpeningIndex = dailyOpenings.findIndex((o) => o === existOpening);
                  dailyOpenings[existOpeningIndex].shifts.push({
                    from: opening[2],
                    to: opening[3],
                  });
                } else {
                  dailyOpenings.push({
                    from_day: i,
                    shifts: [{ from: opening[2], to: opening[3] }],
                  });
                }
              }
            }
          } else if (opening[0] === opening[1]) {
            let existOpening = dailyOpenings.find((o) => o.from_day === opening[0]);
            if (existOpening) {
              let existOpeningIndex = dailyOpenings.findIndex((o) => o === existOpening);
              dailyOpenings[existOpeningIndex].shifts.push({ from: opening[2], to: opening[3] });
            } else {
              dailyOpenings.push({
                from_day: opening[0],
                shifts: [{ from: opening[2], to: opening[3] }],
              });
            }
          } else {
            for (let i = opening[0]; i <= 6; i++) {
              let existOpening = dailyOpenings.find((o) => o.from_day === i);
              if (existOpening) {
                let existOpeningIndex = dailyOpenings.findIndex((o) => o === existOpening);
                dailyOpenings[existOpeningIndex].shifts.push({ from: opening[2], to: opening[3] });
              } else {
                dailyOpenings.push({ from_day: i, shifts: [{ from: opening[2], to: opening[3] }] });
              }
            }
            for (let i = 0; i <= opening[1]; i++) {
              let existOpening = dailyOpenings.find((o) => o.from_day === i);
              if (existOpening) {
                let existOpeningIndex = dailyOpenings.findIndex((o) => o === existOpening);
                dailyOpenings[existOpeningIndex].shifts.push({ from: opening[2], to: opening[3] });
              } else {
                dailyOpenings.push({ from_day: i, shifts: [{ from: opening[2], to: opening[3] }] });
              }
            }
          }
        }
      }
    });
    const weekDays = [0, 1, 2, 3, 4, 5, 6];

    weekDays.forEach((index) => {
      if (
        !dailyOpenings.find((opening) => {
          return (
            opening.from_day === index ||
            (opening.to_day < opening.from_day &&
              opening.to_day <= index &&
              index <= opening.from_day + 7)
          );
        })
      ) {
        dailyOpenings.push({ from_day: index, to_day: index, closed: true });
      }
    });

    return country === "IL"
      ? dailyOpenings.sort((openA, openB) => openA.from_day - openB.from_day)
      : dailyOpenings.sort(
        (openA, openB) => ((openA.from_day + 6) % 7) - ((openB.from_day + 6) % 7)
      );
  };

  const getSkeleton = () => {
    return (
      <div>
        <Skeleton
          height="2.5rem"
          width="20rem"
          borderRadius="10px"
          style={ { marginTop: "2rem", marginBottom: "4rem" } }
        />
        <Skeleton
          height="2rem"
          width="15rem"
          borderRadius="10px"
          style={ { marginBottom: "1rem" } }
        />
        <Skeleton
          height="1.5rem"
          width="10rem"
          borderRadius="10px"
          style={ { marginBottom: "4rem" } }
        />
        <Skeleton
          height="1.5rem"
          width="35rem"
          borderRadius="10px"
          style={ { marginBottom: "1.5rem" } }
        />
        <Skeleton
          height="1.5rem"
          width="35rem"
          borderRadius="10px"
          style={ { marginBottom: "1.5rem" } }
        />
        <Skeleton
          height="1.5rem"
          width="35rem"
          borderRadius="10px"
          style={ { marginBottom: "1.5rem" } }
        />
      </div>
    );
  };

  const getCurrentDay = () => {
    const currentDay = new Date().getDay();
    const index = config.currentCountry !== "IL" ? currentDay - 1 : currentDay;
    return index === -1 ? 6 : index;
  };

  const getLocationHref = () => {
    if (google_place_id) {
      return `https://www.google.com/maps/search/?api=1&query=${location}&query_place_id=${google_place_id}`;
    } else {
      return `http://maps.google.com/?q=${location}`;
    }
  };

  const noInfoLine = <p className={ classes.noInfo }>{ t("no_info") }</p>;

  return isLoading ? (
    getSkeleton()
  ) : (
    <div className={ `${classes.mainContainer} ${className}` }>
      { showTopDivider && (location || daily_opening || phone || reservation) && (
        <div className={ classes.divider }></div>
      ) }
      { <ReactMarkdown id="entity_description" className={ classes.description }>{ description }</ReactMarkdown> }

      { !hideLocation && (
        <div className={ classes.row }>
          <img className={ classes.icon } src={ icons.locationGrey } alt={ iconsAlts.locationGrey } />
          { location ? (
            <a className={ classes.location } target="_blank" href={ getLocationHref() } rel="noreferrer">
              { location }
            </a>
          ) : (
            noInfoLine
          ) }
        </div>
      ) }

      { !hideLocation && (
        <div className={ classes.row }>
          <img className={ classes.icon } src={ icons.distance } alt={ iconsAlts.distance } />
          { distance ? (
            <p className={ classes.distance }>
              { distance } { getCurrentCountry() === "IL" ? t("km") : t("mi") }
            </p>
          ) : (
            noInfoLine
          ) }
        </div>
      ) }

      { !hideSchedule && (
        <div className={ classes.row }>
          <img className={ classes.icon } src={ icons.clockGrey } alt={ iconsAlts.clockGrey } />
          { daily_opening ? (
            <div className={ classes.openHours }>
              { getDisplayHours(daily_opening, getCurrentCountry()).map((opening, index) => (
                <div
                  key={ index }
                  className={ getCurrentDay() == index ? classes.rowBold : classes.scheduleRow }
                >
                  <p className={ classes.openingDay }>
                    { getOpeningDays(opening.from_day, opening.to_day) }
                  </p>
                  <p className={ classes.openingHour }>
                    { opening.all_day
                      ? "24H"
                      : opening.closed
                        ? t("closed")
                        : getOpeningShifts(opening.shifts) }
                  </p>
                </div>
              )) }
            </div>
          ) : (
            noInfoLine
          ) }
        </div>
      ) }

      {(entityType !== BRANDS || phone) && 
      <div className={ classes.row }>
        <img className={ classes.icon } src={ icons.phoneGrey } alt={ iconsAlts.phoneGrey } />
        { phone ? (
          <a className={ classes.phoneNumber } href={ `tel:${phone}` }>
            { phone }
          </a>
        ) : (
          noInfoLine
        ) }
      </div>}
      { reservation && (
        <div className={ classes.row }>
          <img className={ classes.icon } src={ icons.reservation } alt={ iconsAlts.reservation } />
          <a
            className={ classes.reservation }
            href={ reservation }
            target="_blank"
            onClick={ () =>
              logEvents("business_reservation_icon_click", {
                ...eventProps,
                reservation_service: reservationName,
              })
            } rel="noreferrer"
          >
            { t("make_reservation") }{ " " }
            { reservationName == "Restaurant" || reservationName == "Other"
              ? ""
              : //todo :remove on and more sure on us still working
              `${t("on")} ${reservationName}` }
          </a>
        </div>
      ) }
    </div>
  );
};

BusinessDescription.propTypes = {
  description: propTypes.string,
  location: propTypes.string,
  openHours: propTypes.string,
  phone: propTypes.string,
  entityType: propTypes.string,
  reservation: propTypes.string,
};

export default BusinessDescription;
