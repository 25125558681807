import { serverAuth, googleAPIKey } from "../resources/config";
import axios from "axios";
import { getCurrentCountry, getCurrentLocale } from "../utils/utilFunctions";
import config from "../config";
import moment from "moment";

const getPathWithRandom = (resource, isRandom) => {
  let addedString = "";
  if(!isRandom) return resource;

  if(resource.includes("?")) addedString = `&random=${Date.now()}`
  else {
    addedString = `?random=${Date.now()}`
  }

  return resource + addedString;
}

const getAppURl = () => {
  const country = getCurrentCountry();
  const isTest = config.isTest;

  if (country === "US") {
    return `https://appadmin.vegan-friendly.com/api/`;
  } else {
    return `https://${isTest ? "test." : ""}appadmin.vegan-friendly.co.uk/api/`;
  }
};

const APP_URL = getAppURl();

class Api {
  _get(resource, includeToken = false) {
    
    const fullPath = getPathWithRandom(resource, (config.skipCache && !includeToken)) 
      
    
    return this._request(includeToken, "GET", fullPath);
  }

  _post(resource, body, includeToken = true) {
    return this._request(includeToken, "POST", resource, body);
  }


  static _json(res) {
    if (res.ok) {
      return res.json();
    } else {
      throw {
        status: res.status,
        statusText: res.statusText,
      };
    }
  }

  async _request(includeToken, method, resource, body, headers = {}) {
    const _headers = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };

    if (Api.token && includeToken) {
      _headers["Authorization"] = "Bearer " + Api.token;
    }

    headers = Object.assign({}, _headers, headers);

    if (body && body instanceof FormData) {
      headers["Content-Type"] = "application/json";
    }

    body = JSON.stringify(body);

    let url = resource;

    try {
      const date =
        new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();

      global.__DEV__ && console.log();
      return await fetch(url, {
        method,
        headers,
        body,
      }).then(this.constructor._json);
    } catch (e) {
      if (e instanceof Promise) {
        throw await e;
      }
      throw e;
    }
  }

  async testHash(hash) {
    try {
      Api.token = hash;

      const response = await this._get(`${APP_URL}test-hash`, true);
      return response;
    } catch (e) {
      Api.token = null;
      return false;
    }
  }

  async postUserAuth() {
    try {
      const response = await this._post(APP_URL + "auth/local", serverAuth);
      if (response && response.jwt) {
        Api.token = response.jwt;
        localStorage.setItem("login-hash", response.jwt);
        return true;
        // return { token: response.jwt, user: response.user };
      }
      Api.token = null;
      return false;
    } catch (e) {
      Api.token = null;
      return false;
    }
  }

  getCountry() {
    return getCurrentCountry();
  }

  getLocale() {
    return getCurrentLocale();
  }

  getUserHash() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.id : null;
  }

  async getLocationByIP() {
    return this._post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googleAPIKey}`);
  }

  // Entities

  async getWebsiteHomepageEntities(zone) {
    const zoneStr = zone ? `&zone=${zone}` : ``;
    return this._get(
      APP_URL +
        `get-website-homepage-entities?locale=${this.getLocale()}&country=${this.getCountry()}${zoneStr}`
    );
  }

  async getHomePageSearchResults(zone) {
    const zoneStr = zone ? `&zone=${zone}` : ``;
    return this._get(
      APP_URL +
        `get-website-search-entities?locale=${this.getLocale()}&country=${this.getCountry()}${zoneStr}`
    );
  }

  async getSearchResults(zone) {
    const zoneStr = zone ? `&zone=${zone}` : ``;
    return this._get(
      APP_URL +
        `get-website-search-results?locale=${this.getLocale()}&country=${this.getCountry()}${zoneStr}`
    );
  }

  async postNewReview(review) {
    review.reviewer_hash = this.getUserHash();
    return this._post(APP_URL + "reviews", review);
  }

  async getUserPendingReviews() {
    return this._get(APP_URL + `get-user-pending-reviews?user_hash=${this.getUserHash()}`, true); // TODO
  }

  //Restaurant
  async getFullRestaurant(id) {
    return this._get(APP_URL + `get-app-restaurant/${id}`);
  }

  async getRestaurantReviews(id) {
    return this._get(APP_URL + `get-restaurant-reviews/${id}`);
  }

  //Business
  async getFullBusiness(id) {
    return this._get(APP_URL + `get-app-business-branch/${id}`);
  }

  async getBusinessReviews(id) {
    return this._get(APP_URL + `get-business-branch-reviews/${id}`);
  }

  //Recipe
  async getFullRecipe(id) {
    return this._get(APP_URL + `get-app-recipe/${id}`);
  }

  async getRecipeReviews(id) {
    return this._get(APP_URL + `get-recipe-reviews/${id}`);
  }

  //Company

  async getFullCompany(id) {
    return this._get(APP_URL + `get-app-company/${id}`);
  }

  async getFullBlog(slug) {
    return this._get(APP_URL + `get-blog-by-slug/${slug}`);
  }

  async getFullEmployer(id) {
    return this._get(APP_URL + `get-app-employer/${id}`);
  }

  // General

  async getAboutUsItems() {
    return this._get(APP_URL + `get-about-us-items?country=${this.getCountry()}`);
  }

  async getVaCampaigns() {
    return this._get(`https://active.vegan-friendly.co.il/wp-json/v1/campaigns`);
  }

  async getInstagramPost(url, accessToken) {
    return this._get(
      `https://graph.facebook.com/v16.0/instagram_oembed?url=${url}&fields=thumbnail_url&access_token=${accessToken}`
    );
  }

  async contactUs(fullName, email, subject, details, additionalDetails) {
    try {
      const platform = "Web";
      const version = "1.0.0";
      const country = this.getCountry();
      const status = "new";
      const browser = "chrome";

      const body = {
        subject: subject,
        details: details,
        additionalDetails: additionalDetails,
        fullName: fullName,
        email: email,
        platform,
        version,
        browser,
        country,
      };

      const body_user = {
        subject: encodeURI(subject),
        details: encodeURI(details),
        additional_details: encodeURI(additionalDetails),
        full_name: encodeURI(fullName),
        email: email,
        platform,
        version,
        browser,
        country,
      };

      this._post(APP_URL + "notify-mail-website", body);

      const response = await this._post(APP_URL + "user-contacts", body_user);

      return { success: true, code: response.id };
    } catch (e) {
      return { response: { success: false } };
    }
  }

  async filesUpload(files) {
    const _headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + Api.token,
    };
    const headers = Object.assign({}, _headers);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(APP_URL + "upload", formData, {
        headers,
      });

      if (response && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (e) {
      console.log("Files: error we got!! " + JSON.stringify(e));
    }
  }
}

export default new Api();
