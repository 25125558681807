import { getCurrentCountry } from "../utilFunctions";

/* 
Gets a list of only tag ids and returns a list of full tags items based on the given ids
*/
export const prepareFullTags = (tagIds, fullTags) => {
  if (tagIds && fullTags) {
    return tagIds.map((tagId) => {
      const found = fullTags.find((tag) => tag.id === tagId);
      return found ? found : { id: tagId, name: "" };
    });
  }
};

/* 
Gets a list of only categories ids and returns a list of full categories items based on the given ids
*/
export const prepareFullCategories = (categoriesId, fullCategories) => {
  if (categoriesId && fullCategories) {
    const fullList = categoriesId.map((categoryId) => {
      const found = fullCategories.find((category) => category.id === categoryId);
      return found ? found : { id: -1, category_name: "" };
    });
    return fullList.filter((c) => c.id != -1);
  }
  
  return [];
};

export const addTagsFieldToEntity = (entity) => {
  entity.is_vegan = findTag("Vegan", entity.tags);
  entity.is_certified = findTag("Certified", entity.tags);
  entity.is_kosher = findTag("Kosher", entity.tags);
  entity.is_gluten_free = findTag("Gluten Free", entity.tags);
};

/*
Gets a specific tagName and return true only if the tag is included in the tagList
*/
export const findTag = (tagName, tagList) => {
  if (tagList && tagList.length) {
    const found = tagList.find((t) => t.name === tagName);
    return found != null;
  }
};

/*
Gets 2 location with lat lon and return the distance between them
*/
export const calculateDistance = (location1, location2) => {
  const unit = getCurrentCountry() == "IL" ? "K" : "M";
  if (location1 && location2 && location1.lat && location1.lon && location2.lat && location2.lon) {
    const lon1 = location1.lon;
    const lat1 = location1.lat;
    const lon2 = location2.lon;
    const lat2 = location2.lat;
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const radlon1 = (Math.PI * lon1) / 180;
    const radlon2 = (Math.PI * lon2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
  return null;
};

export const numberToFixed = (number) => {
  try {
    return number.toFixed(1);
  } catch {
    console.log("numberToFixed ERROR!!!!!");
    return 0;
  }
};
