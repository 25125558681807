import classes from "./GalleryModal.module.css";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import images, { imagesAlts } from "../../resources/images";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { s3 } from "../../resources/config";
import { Carousel } from "react-responsive-carousel";
import icons, { iconsAlts } from "../../resources/icons";
import { getCurrentCountry, getCurrentLocale } from "../../utils/utilFunctions";

const GalleryModal = ({ show, onHide, selectedIndex, coverImages }) => {
  const getSelectedImageByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return coverImages.length - selectedIndex - 1;
    } else {
      return selectedIndex;
    }
  };

  const getCoverImagesByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return [...coverImages].reverse();
    } else {
      return coverImages;
    }
  };

  const arrowPrevHandler = (clickHandler, _, label) => {
    return (
      <button
        type="button"
        className={`${classes.previousButton}`}
        onClick={() => {
          clickHandler();
        }}
      >
        <img src={images.galleryPreviousButton} alt={imagesAlts.galleryPreviousButton} />
      </button>
    );
  };

  const arrowNextHandler = (clickHandler, _, label) => {
    return (
      <button
        type="button"
        className={`${classes.nextButton}`}
        onClick={() => {
          clickHandler();
        }}
      >
        <img src={images.galleryNextButton} alt={imagesAlts.galleryNextButton} />
      </button>
    );
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.modalContainer}
      backdropClassName={classes.backdrop}
      onHide={onHide}
      size={"xl"}
      centered
      dialogClassName={classes.modalClass}
    >
      <img
        className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
        src={icons.closeXWhite}
        onClick={onHide}
        alt={iconsAlts.closeXWhite}
      />
      <Carousel
        className={classes.carousel}
        autoPlay={false}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={true}
        dynamicHeight={false}
        useKeyboardArrows={true}
        infiniteLoop={true}
        renderArrowPrev={arrowPrevHandler}
        renderArrowNext={arrowNextHandler}
        selectedItem={getSelectedImageByLocale()}
      >
        {getCoverImagesByLocale().map((image, index) => (
          <div key={index} className={`${classes.mainContainer} }`}>
            <img className={classes.image} src={image} alt="gallery image" />
          </div>
        ))}
      </Carousel>
    </Modal>
  );
};

export default GalleryModal;
