import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as restaurantActions from "../../actions/restaurantActions";
import {
  BusinessBenefits,
  BusinessDescription,
  BusinessMap,
  BusinessMenu,
  BusinessSocial,
} from "../../components/EntityPages/BusinessAndRestaurant";
import SEO from "../../utils/SEO";
import { EntityHeader, EntityReviews } from "../../components/EntityPages";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import classes from "./Restaurant.module.css";
import GreyIconTags from "../../components/Entities/IconTags/GreyIconTags/GreyIconTags";
import { restaurantUtils, reviewUtils } from "../../utils/entityUtils";
import { RESTAURANTS } from "../../resources/config";
import { logEvents } from "../../eventsManager";
import { InputGroup } from "react-bootstrap";
import config from "../../config";


const Restaurant = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [preparedRestaurant, setPreparedRestaurant] = useState({});
  const [restaurantEventProps, setRestaurantEventProps] = useState({});

  const userLocation = useSelector((state) => state.user.userLocation);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const entityTags = useSelector((state) => state.entities.entityTags);
  const reviewHashTags = useSelector((state) => state.entities.reviewHashTags);
  const pendingReviews = useSelector((state) => state.user.userPendingReviews);

  const { restaurantId } = useParams();
  const fullRestaurant = useSelector((state) => state.restaurant.fullRestaurant);

  useEffect(() => {
    if (preparedRestaurant.id != restaurantId) {
      setIsLoading(true);
      restaurantActions.getFullRestaurant(dispatch, restaurantId).catch(() => {
        navigate("/not-found"); 
      });;
    }
    window.scrollTo(0, 0);
  }, [restaurantId]);

  const getRestaurantEventsProps = (preparedRestaurant) => {
    return {
      business_id: preparedRestaurant.id,
      business_type: RESTAURANTS,
      business_name: preparedRestaurant.restaurant_name,
      business_address: preparedRestaurant.address,
      chain_id: preparedRestaurant.chainId,
      chain_name: preparedRestaurant.chainName,
    };
  };

  useEffect(() => {
    if (
      fullRestaurant &&
      entityCategories &&
      entityTags &&
      reviewHashTags &&
      reviewHashTags.restaurantTags &&
      userLocation &&
      pendingReviews
    ) {

      if (fullRestaurant.id && fullRestaurant.id == restaurantId) {
        const preparedRes = restaurantUtils.prepareRestaurant(
          fullRestaurant,
          entityCategories.restaurants,
          entityTags,
          userLocation.coords
        );

        preparedRes.reviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.restaurantTags)
        );

        preparedRes.userPendingReviews = pendingReviews.filter(
          (review) => review.entity_type == "restaurant" && review.entity_id == restaurantId
        );

        preparedRes.userPendingReviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.restaurantTags)
        );

        setPreparedRestaurant(preparedRes);
        const props = getRestaurantEventsProps(preparedRes);

        logEvents("screen_business_open", props);
        setRestaurantEventProps(props);
        setIsLoading(false);
      }
    }
  }, [fullRestaurant, entityCategories, entityTags, reviewHashTags, userLocation, pendingReviews]);

  const componentToPrint = useRef();

  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const isUS = config.currentCountry === "US";

  return (
    <div className={classes.mainContainer} ref={componentToPrint}>
      <div>
        <EntityHeader
          entityType={RESTAURANTS}
          className={classes.headerContainer}
          title={preparedRestaurant.restaurant_name}
          thumbnail={preparedRestaurant.logo ? preparedRestaurant.logo : ""}
          rating={preparedRestaurant.rating}
          numOfReviews={preparedRestaurant.num_of_reviews}
          categories={preparedRestaurant.rest_categories}
          coverImages={
            preparedRestaurant.menu && preparedRestaurant.menu.food_pics.length > 0
              ? preparedRestaurant.menu.food_pics.map((pic) => pic.url)
              : []
          }
          // coverImages={[]}
          isLoading={isLoading}
          componentToPrint={reactToPrintContent}
        />
        <div className={classes.gridContainer}>
          <GreyIconTags
            entity={preparedRestaurant}
            className={classes.restaurantIcons}
            showNames={true}
            isLoading={isLoading}
            showLocation={false}
          />
          <BusinessDescription
            className={classes.descriptionContainer}
            description={preparedRestaurant.description}
            distance={preparedRestaurant.distance}
            location={preparedRestaurant.address}
            google_place_id={preparedRestaurant.google_place_id}
            daily_opening={
              preparedRestaurant.schedule ? preparedRestaurant.schedule.daily_opening : ""
            }
            phone={preparedRestaurant.phone_number}
            reservationName={preparedRestaurant.reservation_name}
            reservation={preparedRestaurant.reservation_url}
            isLoading={isLoading}
            eventProps={restaurantEventProps}
          />

          {preparedRestaurant.location && (
            <BusinessMap
              className={classes.mapContainer}
              entity={preparedRestaurant}
              isLoading={isLoading}
              lastUpdate={preparedRestaurant.last_update}
            />
          )}

          {!isUS && (
            <BusinessBenefits
              entityType={RESTAURANTS}
              entity={preparedRestaurant}
              businessIcon={preparedRestaurant.logo ? preparedRestaurant.logo : ""}
              className={classes.benefitsContainer}
              benefits={preparedRestaurant.discount_list}
              isLoading={isLoading}
              entityName={preparedRestaurant.restaurant_name}
            />
          )}

          {!isLoading && (
            <BusinessMenu
              className={classes.menuContainer}
              menu={preparedRestaurant.menu}
              entityName={preparedRestaurant.restaurant_name}
            />
          )}
          {!isLoading && (
            <BusinessSocial
              className={classes.socialsContainer}
              restaurantName={preparedRestaurant.restaurant_name}
              websiteLink={preparedRestaurant.website_url}
              instagramLink={preparedRestaurant.instagram_url}
              facebookLink={preparedRestaurant.facebook_url}
              linkedinLink={preparedRestaurant.linkedin_url}
            />
          )}
        </div>
        {!isLoading && (
          <EntityReviews
            className={classes.reviewsContainer}
            numOfReviews={preparedRestaurant.num_of_reviews}
            rating={preparedRestaurant.rating}
            reviews={preparedRestaurant.reviews}
            pendingReviews={preparedRestaurant.userPendingReviews}
            entityType={RESTAURANTS}
            entityId={preparedRestaurant.id}
            entityName={preparedRestaurant.restaurant_name}
            onRefreshReviews={() => restaurantActions.getFullRestaurant(dispatch, restaurantId)}
            eventProps={restaurantEventProps}
          />
        )}
      </div>
    </div>
  );
};

export default Restaurant;
