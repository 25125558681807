import { t } from "i18next";
import icons, { iconsAlts } from "../../../../resources/icons";
import classes from "./GreyIconTags.module.css";
import Skeleton from "react-loading-skeleton";
import { getCurrentCountry } from "../../../../utils/utilFunctions";

const GreyIconTags = ({ entity, className, showNames = false, showLocation = true, isLoading }) => {
  const params = {
    distance: entity.distance,
    isOpen: entity.is_open,
    isKosher: entity.is_kosher,
    isVegan: entity.is_vegan,
    hasDiscount: entity.has_discount,
    hasDelivery: entity.has_delivery,
    isCertified: entity.is_certified,
    isGlutenFree: entity.is_gluten_free,
    hasVeganOptions: entity.vegan_options,
  };

  return !isLoading ? (
    <div className={`${classes.mainContainer} + ${className}`}>
      {showLocation && params.distance && (
        <div className={classes.row}>
          <p className={classes.distance}>
            {params.distance} {getCurrentCountry() === "IL" ? t("km") : t("mi")}
          </p>
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.hasDiscount && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.benefitTag} alt={iconsAlts.benefitTag} />
          {showNames && <p className={classes.name}>{t("benefit")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.isOpen && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.openNowTag} alt={iconsAlts.openNowTag} />
          {showNames && <p className={classes.name}>{t("open_now")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.isVegan && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.veganTag} alt={iconsAlts.veganTag} />
          {showNames && <p className={classes.name}>{t("100_vegan")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.hasDelivery && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.deliveryTag} alt={iconsAlts.deliveryTag} />
          {showNames && <p className={classes.name}>{t("delivery")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.isKosher && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.kosherTag} alt={iconsAlts.kosherTag} />
          {showNames && <p className={classes.name}>{t("kosher")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.isCertified && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.certifiedTag} alt={iconsAlts.certifiedTag} />
          {showNames && <p className={classes.name}>{t("certified")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.isGlutenFree && (
        <div className={classes.row}>
          <img className={classes.icon} src={icons.glutenFreeTag} alt={iconsAlts.glutenFreeTag} />
          {showNames && <p className={classes.name}>{t("gluten_free")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
      {params.hasVeganOptions && (
        <div className={classes.row}>
          <img
            className={classes.icon}
            src={icons.veganOptionsTags}
            alt={iconsAlts.veganOptionsTags}
          />
          {showNames && <p className={classes.name}>{t("vegan_options")}</p>}
          <p className={classes.dot}>•</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default GreyIconTags;
