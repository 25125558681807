import { t } from "i18next";
import { useState } from 'react';
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import { Carousel } from "react-responsive-carousel";
import icons from "../../../resources/icons";
import images, { imagesAlts } from "../../../resources/images";
import { getCurrentLocale } from "../../../utils/utilFunctions";
import classes from "./EntityPictures.module.css";



const EntityPictures = ({ coverImages, onImageClick, isLoading }) => {
  const [imageDimensions, setImageDimensions] = useState([]);
  const getGridStyle = () => {
    switch (coverImages.length) {
      case 1:
        return classes.grid1;
      case 2:
        return classes.grid2;
      case 3:
        return classes.grid3;
      case 4:
        return classes.grid4;
      case 5:
        return classes.grid5;

    }

    if (coverImages.length > 5) return classes.grid5
  };

  const getSelectedItemByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return coverImages.length - 1;
    } else {
      return 0;
    }
  };

  const getCoverImagesByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return [...coverImages].reverse();
    } else {
      return coverImages;
    }
  };

  const setImageSize = (index, image) => {
    const img = new Image()
    img.src = "https://vf-uk-images.s3.eu-central-1.amazonaws.com/03_683b25e3c1.jpg"


    const dimensions = { width: img.width, height: img.height };
    setImageDimensions(prevDims => {
      const newDims = [...prevDims];
      newDims[index] = dimensions;
      return newDims;
    });
  };


  const isAspectRatioClose = (width, height) => {
    const ratio = width / height;
    const squareRatio = 1; // Aspect ratio for 1:1
    const fourThreeRatio = 4 / 3; // Aspect ratio for 4:3

    // Decide the threshold for how close the ratio needs to be
    const threshold = 0.35; // 5% tolerance for example
    console.log('ratio check:', Math.abs(ratio - squareRatio) <= threshold ||
      Math.abs(ratio - fourThreeRatio) <= threshold)
    // Check if the aspect ratio is close to 1:1 or 4:3 within the threshold
    return (
      Math.abs(ratio - squareRatio) <= threshold ||
      Math.abs(ratio - fourThreeRatio) <= threshold
    );
  };


  return isLoading ? (
    <Skeleton className={ classes.cover } />
  ) : coverImages && coverImages.length > 0 ? (
    isMobile ? (
      <Carousel
        className={ classes.imagesContainer }
        autoPlay={ true }
        showArrows={ true }
        showStatus={ false }
        showThumbs={ false }
        dynamicHeight={ true }
        onClickItem={ () => onImageClick(0) }
        selectedItem={ getSelectedItemByLocale() }
      >
        { getCoverImagesByLocale().map((cover, index) => (
          <img key={ index } className={ classes.cover } src={ cover } alt="cover photo" />
        )) }
      </Carousel>
    ) : (
      <div className={ getGridStyle() }>
        { coverImages.slice(0, 5).map((cover, index) => {
          return (
            <div key={ index } className={ classes.gridItem } onClick={ () => onImageClick(index) }>
              <img onLoad={ () => { setImageSize(index, cover) } } className={ classes.images } style={ {
                objectPosition: imageDimensions[index] &&
                  (
                    imageDimensions[index].width &&
                    imageDimensions[index].height &&
                    (
                      isAspectRatioClose(imageDimensions[index].width, imageDimensions[index].height)
                    )
                  )
                  ? "top"
                  : "50% 50%"

              } } src={ cover } alt="cover photo" />
              <div className={ classes.showMoreImagesContainer }>
                <p className={ classes.showMoreText }>+{ coverImages.length - 5 }</p>
              </div>
            </div>
          )
        }) }
      </div>


    )
  ) : (
    <div className={ classes.noImageContainer }>
      <img className={ classes.defaultImage } src={ images.veganFriendlyWhite } />
      <h4 className={ classes.defaultImageText }>
        { t("no_image_place_holder_title") }
        <br />
        <b>{ t("no_image_place_holder_subtitle") }</b>
      </h4>
    </div>
  );
};

export default EntityPictures;